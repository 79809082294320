import Vue from 'vue'
import Router from 'vue-router'
import Login from './views/login.vue'
import VerbaleSP from "./views/verbaleSP"
import Home from "./views/home"
import Firma from "./views/modificaFirma"
import VerbaleGVR from "./views/verbaleGVR"
import VerbaliRecenti from "./views/verbaliRecenti"
import VerbaleDismissioneSPSC from "./views/verbaleDismissioneSPSC"
import VerbaleDismissioneGVR from "./views/verbaleDismissioneGVR"
import VerbaleFuniCatene from "./views/verbaleFuniCatene"
import EsportazioneVerbali from './views/esportazioneVerbali.vue'
import ProgettiDaFatturare from './views/progettiDaFatturare.vue'
import DownloadOrder from './views/downloadOrder.vue'
import Veicoli from './views/veicoli.vue'
import RicercaVerbale from './views/ricercaVerbale.vue'
import PianificazioneAttività from './views/pianificazioneAttivita.vue'
import GoogleMaps from './views/googleMaps.vue'
import Smartcal from './views/smartcal.vue'
import ScadenziarioVerificheRidotto from './views/scadenziarioVerificheRidotto.vue'
import VerbaleTaraturaValvole from './views/verbaleTaraturaValvole.vue'
import Straordinari from './views/straordinari.vue'
import ListaDDT from './views/listaDDT.vue'

Vue.use(Router)

export default new Router({
  //mode: 'history',
  routes: [{
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: "/verbaleSPSC",
    name: "Certifico Verbale SPSC",
    component: VerbaleSP,
    meta: { requiresAuth: true, checkAccess: true }
  },
  {
    path: "/",
    name: "Certifico Home",
    component: Home,
    meta: { requiresAuth: true, checkAccess: true }
  },
  {
    path: "/firma",
    name: "Certifico Modifica Firma",
    component: Firma,
    meta: { requiresAuth: true, checkAccess: true }
  },
  {
    path: "/verbaleGVR",
    name: "Certifico Verbale GVR",
    component: VerbaleGVR,
    meta: { requiresAuth: true, checkAccess: true }
  },
  {
    path: "/recenti",
    name: "Verbali recenti",
    component: VerbaliRecenti,
    meta: { requiresAuth: true, checkAccess: true }
  },
  {
    path: "/verbaleDismissioneSPSC",
    name: "Certifico Verbale Dismissione SPSC",
    component: VerbaleDismissioneSPSC,
    meta: { requiresAuth: true, checkAccess: true }
  },
  {
    path: "/verbaleDismissioneGVR",
    name: "Certifico Verbale Dismissione GVR",
    component: VerbaleDismissioneGVR,
    meta: { requiresAuth: true, checkAccess: true }
  },
  {
    path: "/verbaleFuniCatene",
    name: "Certifico Verbale Funi e Catene",
    component: VerbaleFuniCatene,
    meta: { requiresAuth: true, checkAccess: true }
  },
  {
    path: "/esportazioneVerbali",
    name: "Esportazione Verbali",
    component: EsportazioneVerbali,
    meta: { requiresAuth: true, checkAccess: true }
  },
  {
    path: "/progettiDaFatturare",
    name: "Progetti da Fatturare",
    component: ProgettiDaFatturare,
    meta: { requiresAuth: true, checkAccess: true }
  },
  {
    path: "/downloadOrder",
    name: "Certifico Download Order",
    component: DownloadOrder,
    meta: { requiresAuth: true, checkAccess: true }
  },
  {
    path: "/veicoli",
    name: "Certifico Veicoli",
    component: Veicoli,
    meta: { requiresAuth: true, checkAccess: true }
  },
  {
    path: "/ricercaVerbale",
    name: "Certifico Ricerca Verbale",
    component: RicercaVerbale,
    meta: { requiresAuth: true, checkAccess: true }
  },
  {
    path: "/pianificazioneAttivita",
    name: "Certifico Pianificazione Attività",
    component: PianificazioneAttività,
    meta: { requiresAuth: true, checkAccess: true }
  },
  {
    path: "/googleMaps",
    name: "Certifico Google Maps",
    component: GoogleMaps,
    meta: { requiresAuth: true, checkAccess: true }
  },
  {
    path: "/smartcal",
    name: "Certifico Smartcal",
    component: Smartcal,
    meta: { requiresAuth: true, checkAccess: true }
  },
  {
    path: "/scadenziarioVerificheRidotto",
    name: "Certifico Scadenziario Verifiche Ridotto",
    component: ScadenziarioVerificheRidotto,
    meta: { requiresAuth: true, checkAccess: true }
  },
  {
    path: "/verbaleTaraturaValvole",
    name: "Certifico Verbale Taratura Valvole",
    component: VerbaleTaraturaValvole,
    meta: { requiresAuth: true, checkAccess: true }
  },
  {
    path: "/straordinari",
    name: "Certifico Straordinari",
    component: Straordinari,
    meta: { requiresAuth: true, checkAccess: true }
  },
  {
    path: "/listaDDT",
    name: "Certifico Lista DDT",
    component: ListaDDT,
    meta: { requiresAuth: true, checkAccess: true }
  }
  ]
})