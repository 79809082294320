<template>
  <v-dialog
    v-model="dialog"
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card>
      <v-card-title>Inserisci Evento</v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <DatePickerText
                outlined
                dense
                label="Data Inizio"
                v-model="startDate"
                hide-details
              ></DatePickerText>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <TimePickerText
                outlined
                dense
                label="Ora Inizio"
                v-model="startTime"
                format="24hr"
                hide-details
              ></TimePickerText>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <DatePickerText
                outlined
                dense
                label="Data Fine"
                v-model="endDate"
                hide-details
              ></DatePickerText>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <TimePickerText
                outlined
                dense
                label="Ora Fine"
                v-model="endTime"
                format="24hr"
                hide-details
              ></TimePickerText>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-autocomplete
                label="Personale"
                dense
                outlined
                :items="users"
                item-text="name"
                item-value="ad_user_id"
                v-model="selectedUsers"
                multiple
                chips
                clearable
                :disabled="request.r_Request_ID > 0"
                hide-details
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                outlined
                v-model="summary"
                label="Oggetto"
                hide-details
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn text color="error" @click="deleteRequest">Elimina</v-btn>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="close">Chiudi</v-btn>
        <v-btn
          text
          color="success"
          @click="saveRequest"
          :disabled="saveDisabled"
          >Salva</v-btn
        >
      </v-card-actions>
    </v-card>
    <ConfirmDialog ref="confirm"></ConfirmDialog>
  </v-dialog>
</template>

<script>
/*eslint-disable */
import MixingCommonComp from "../mixin/MixingCommonComp";
import ConfirmDialogVue from "./ConfirmDialog.vue";

import DatePickerText from "./DatePickerText.vue";
import TimePickerText from "./TimePickerText.vue";
import ConfirmDialog from "./ConfirmDialog.vue";

export default {
  mixins: [MixingCommonComp],
  components: { DatePickerText, TimePickerText, ConfirmDialog },
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      users: [],
      selectedUsers: [],
      request: {},
      startTime: "",
      endTime: "",
      startDate: "",
      endDate: "",
      r_request_id: 0,
      summary: "",
    };
  },
  methods: {
    open(event) {
      this.request = event.request;
      let date = new Date(this.request.startTime);
      date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
      this.startDate = date.toISOString().substring(0, 10);
      this.startTime = date.toISOString().substring(11, 16);

      date = new Date(this.request.endTime);
      date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
      this.endDate = date.toISOString().substring(0, 10);
      this.endTime = date.toISOString().substring(11, 16);
      this.r_request_id = this.request.r_Request_ID;
      this.summary = this.request.summary;
      this.selectedUsers = [this.request.ad_User_ID]

      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    close() {
      this.dialog = false;
      this.resolve();
    },
    saveRequest() {
      let startTime = this.startDate + "T" + this.startTime + ":00";
      let endTime = this.endDate + "T" + this.endTime + ":00";

      let summary = this.summary ? this.summary : this.request.requestTypeName || " ";

      let parameters = {};
      parameters.data = {
        startTime: startTime,
        endTime: endTime,
        r_Request_ID: this.r_request_id,
        summary: summary,
        ad_User_IDs: this.selectedUsers
      };
      parameters.idempiereRestPath = "vecos/saveRequest";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        this.dialog = false;
        if (response.data.result == "OK") {
          this.resolve(response);
        } else {
          this.reject(response);
        }
      });
    },
    async deleteRequest() {
      if (
        !(await this.$refs.confirm.open(
          "Elimina Evento",
          "Vuoi eliminare questo evento?",
          { color: "red" }
        ))
      ) {
        return;
      }
      this.showLoadingDialog(true, "Eliminazione in corso...");
      let parameters = {};
      parameters.data = {
        r_Request_ID: this.r_request_id,
      };
      parameters.idempiereRestPath = "vitech/deleteRequest";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        this.showLoadingDialog(false);
        this.dialog = false;
        if (response.data.result == "OK") {
          this.resolve(response);
        } else {
          this.reject(response);
        }
      });
    },
    getSalesReps() {
      let parameters = {};
      parameters.data = {};
      parameters.idempiereRestPath = "effecitech/getSalesReps";
      this.$store.dispatch("callIdempiereRest", parameters).then((response) => {
        this.users = response.data.salesReps;
      });
    },
  },
  mounted(){
    this.getSalesReps();
  },
  computed: {
    saveDisabled() {
      if (!this.startDate) return true;
      if (!this.endDate) return true;
      if (!this.startTime) return true;
      if (!this.endTime) return true;

      let startDate = new Date(this.startDate + "T" + this.startTime);
      let endDate = new Date(this.endDate + "T" + this.endTime);

      if(startDate.getTime() > endDate.getTime()){
        return true;
      }

      return false;
    },
    userDisabled(){
      if(this.$session.get("ad_user_id") != this.request.ad_User_ID){
        return true;
      }

      return false;
    }
  },
};
</script>

<style>
</style>