var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[(_vm.window)?_c('span',[_c('Tab',{ref:"tab",attrs:{"ad_tab_id":_vm.topTab.AD_Tab_ID,"language":_vm.language,"hide-save":_vm.hideSave,"hide-tabs":_vm.hideTabs,"hide-delete":_vm.hideDelete,"hide-copy":_vm.hideCopy},on:{"save":(success) => {
          _vm.$emit('save', success);
        },"delete":(success) => {
          _vm.$emit('delete', success);
        },"copy":(success) => {
          _vm.$emit('copy', success);
        },"change":(value) => {
          _vm.$emit('change', value);
        },"datafetched":(value) => {
          _vm.$emit('datafetched', value);
        }},scopedSlots:_vm._u([{key:"tab-header",fn:function({ tabName }){return [_vm._t("tab-header",null,{"tabName":tabName})]}},{key:"tab-footer",fn:function({
          saveRecord,
          hideSave,
          copyRecord,
          hideCopy,
          deleteRecord,
          hideDelete,
        }){return [_vm._t("tab-footer",null,{"saveRecord":saveRecord,"hideSave":hideSave,"copyRecord":copyRecord,"hideCopy":hideCopy,"deleteRecord":deleteRecord,"hideDelete":hideDelete})]}},{key:"tab-save",fn:function({ saveRecord, hideSave }){return [_vm._t("tab-save-record",null,{"saveRecord":saveRecord,"hideSave":hideSave})]}},{key:"tab-copy-record",fn:function({ copyRecord, hideCopy }){return [_vm._t("tab-copy-record",null,{"copyRecord":copyRecord,"hideCopy":hideCopy})]}},{key:"tab-delete-record",fn:function({
          deleteRecord,
          hideDelete,
        }){return [_vm._t("tab-delete-record",null,{"deleteRecord":deleteRecord,"hideDelete":hideDelete})]}}],null,true),model:{value:(_vm.record_id),callback:function ($$v) {_vm.record_id=$$v},expression:"record_id"}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }